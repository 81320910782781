import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../../shared/hooks/useModal/useModal';
import { ReprintInnerModal } from './ReprintInnerModal';
import { useSelector } from 'react-redux';
import { seriesReportSelector } from '../../../EventsLog/SeriesReport/services/selector';
import styles from './styles.module.scss';
import { Spinner } from '../../Spinner/Spinner';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface IReprintModalProps {
  isShown: boolean;
  params: any;
  closeModal: () => void;
}

export const ReprintModal = ({ isShown, closeModal, params }: IReprintModalProps) => {
  const { isShow: isShowInnerModal, openModal: openInnerModal, closeModal: closeInnerModal } = useModal();
  const { innerModalLoading, postUnreserveMarkingCodes } = useSelector(seriesReportSelector);

  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={isShown}
        onClose={() => {
          closeModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <span className={styles.responseText}>{postUnreserveMarkingCodes ?? ''}</span>
          <div className={styles.spinnerWrapper}>{innerModalLoading && <Spinner />}</div>
          <div className={styles.buttonsWrapper}>
            <CloseIcon
              onClick={() => {
                closeModal();
              }}
            />
          </div>
          <div className={styles.wrapper}>
            <Button variant="contained" className={styles.btn} onClick={openInnerModal}>
              <span className={styles.btnText}>{t('Передрукувати неагреговані коди')}</span>
            </Button>
          </div>
        </Box>
      </Modal>

      <ReprintInnerModal
        isShown={isShowInnerModal}
        innerModalLoading={innerModalLoading}
        closeModal={closeInnerModal}
        params={params}
      />
    </>
  );
};
