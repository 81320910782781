import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST,
  GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST,
  GET_INVOICES_REQUEST,
} from './constants';
import {
  getInvoicesRequest,
  getInvoicesSuccess,
  getInvoicesError,
  getGlobalExcelReportInvoicesError,
  getGlobalExcelReportInvoicesRequest,
  getGlobalExcelReportInvoicesSuccess,
  getDetailedExcelReportInvoicesRequest,
  getDetailedExcelReportInvoicesSuccess,
  getDetailedExcelReportInvoicesError,
} from './actions';
import { Invoices } from '../../../../entities/Invoices/service';

function* getInvoicesRequestSaga(action: ReturnType<typeof getInvoicesRequest>) {
  try {
    const response: AxiosResponse = yield call(Invoices.getInvoices, action.payload);
    const invoices = response.data.ResponseBody;
    yield put(getInvoicesSuccess(invoices));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getInvoicesError(error));
  }
}

function* getGlobalExcelReportRequestSaga(action: ReturnType<typeof getGlobalExcelReportInvoicesRequest>) {
  try {
    yield call(Invoices.getGlobalExcelReport, action.payload);

    yield put(getGlobalExcelReportInvoicesSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getGlobalExcelReportInvoicesError(error));
  }
}

function* getDetailedExcelReportRequestSaga(action: ReturnType<typeof getDetailedExcelReportInvoicesRequest>) {
  try {
    // @ts-expect-error
    const response = yield call(Invoices.geDetailedExcelReport, action.payload);

    if (response.status === 204) {
      yield put(
        getDetailedExcelReportInvoicesError({
          message: 'по документу не відвантажувались позиції для даної країни.',
          name: ' ',
          config: {
            url: '/Blob/Download',
          },
          status: 204,
        }),
      );
    } else {
      yield put(getDetailedExcelReportInvoicesSuccess(response));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getDetailedExcelReportInvoicesError(error));
  }
}

export function* InvoiceReportSagaWatcher() {
  yield takeLatest(GET_INVOICES_REQUEST, getInvoicesRequestSaga);
  yield takeLatest(GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST, getGlobalExcelReportRequestSaga);
  yield takeLatest(GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST, getDetailedExcelReportRequestSaga);
}
