import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputSearch from '../../common/InputSearch';
import { PrintCodesTable } from '../../common/PrintCodesTable/PrintCodesTable';
import { Spinner } from '../../common/Spinner/Spinner';
import ArrowForwardIcon from '../../../icons/arrow_forward.svg';
import ArrowBackIcon from '../../../icons/arrow_back.svg';

import styles from './PrintCodes.module.scss';
import { postFilterPrintJobRequest } from './services/actions';
import { printCodesSelector } from './services/selector';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';

const calendarStyle = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#ffffff !important',
    height: 30,
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
};

export const PrintCodes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let timeoutId: NodeJS.Timeout | null = null;

  const [calendarDate, setCalendarDate] = useState<any>(new Date());

  const {
    printCodes,
    loading,
    getAllPrintJobsErrorResponse,
    cancelPrintJobsErrorResponse,
    postUnreserveMarkingCodesErrorResponse,
    printCodesDetailedErrorResponse,
  } = useSelector(printCodesSelector);

  const [tableData, setTableData] = useState<any[]>(printCodes);

  const handleMonthChange = (increment: number) => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCalendarDate(newDate);
  };

  const changeDateCalendar = (calendarValue: any) => {
    const newDate = new Date(calendarValue);
    newDate.setMonth(newDate.getMonth());
    setCalendarDate(newDate);
  };

  const filterPrintJobs = () => {
    dispatch(
      postFilterPrintJobRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
      }),
    );
  };

  useEffect(() => {
    timeoutId = setTimeout(() => {
      filterPrintJobs();
      timeoutId = null;
    }, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [calendarDate]);

  useEffect(() => {
    dispatch(
      postFilterPrintJobRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
      }),
    );
  }, []);

  useEffect(() => {
    if (
      (Object.keys(getAllPrintJobsErrorResponse).length !== 0 && getAllPrintJobsErrorResponse.status === 403) ||
      (Object.keys(cancelPrintJobsErrorResponse).length !== 0 && cancelPrintJobsErrorResponse.status === 403) ||
      (Object.keys(postUnreserveMarkingCodesErrorResponse).length !== 0 &&
        postUnreserveMarkingCodesErrorResponse.status === 403) ||
      (Object.keys(printCodesDetailedErrorResponse).length !== 0 && printCodesDetailedErrorResponse.status === 403)
    ) {
      navigate('/error');
    }
  }, [getAllPrintJobsErrorResponse]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getAllPrintJobsErrorResponse).length !== 0 ? (
        <ErrorResponse error={getAllPrintJobsErrorResponse} />
      ) : null}
      {Object.keys(cancelPrintJobsErrorResponse).length !== 0 ? (
        <ErrorResponse error={cancelPrintJobsErrorResponse} />
      ) : null}
      {Object.keys(postUnreserveMarkingCodesErrorResponse).length !== 0 ? (
        <ErrorResponse error={postUnreserveMarkingCodesErrorResponse} />
      ) : null}
      {Object.keys(printCodesDetailedErrorResponse).length !== 0 ? (
        <ErrorResponse error={printCodesDetailedErrorResponse} />
      ) : null}
      <section className={styles.header}>
        <div className={styles.group}>
          <span className={styles.subtitle}>Завдання на друк</span>
          {loading ? <Spinner /> : null}
        </div>
        <span>Показано виконані і поточні завдання за період</span>
      </section>
      <section className={styles.block}>
        <div className={styles.tools}>
          <div className={styles.calendarBlock}>
            <div className={styles.calendarItem}>
              <span>Дані за пeріод:</span>
              <div className={styles.calendarRow}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="MM/YYYY"
                    value={calendarDate}
                    views={['year', 'month']}
                    onChange={changeDateCalendar}
                    renderInput={(params) => <TextField sx={calendarStyle} {...params} />}
                  />
                  <img src={ArrowBackIcon} onClick={() => handleMonthChange(-1)} />
                  <img src={ArrowForwardIcon} onClick={() => handleMonthChange(1)} />
                </LocalizationProvider>
              </div>
            </div>
            <Button variant="contained" className={styles.btn} onClick={filterPrintJobs}>
              <span className={styles.btnText}>Пошук</span>
            </Button>
          </div>
        </div>
        <div className={styles.searchBlock}>
          <span>Фільтр по всім полям:</span>
          <InputSearch setTableData={setTableData} data={printCodes} clearOnPropsChange={true} />
        </div>
      </section>
      <PrintCodesTable data={tableData} filterPrintJobs={filterPrintJobs} />
    </div>
  );
};
