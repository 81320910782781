import { Box, Button, Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { aggregationReportSelector } from '../services/selector';
import styles from './ReportModal.module.scss';
import { Spinner } from '../../../common/Spinner/Spinner';
import CloseIcon from '@mui/icons-material/Close';
import { putUpdateStatusRequest, putUpdateStatusSuccess } from '../services/actions';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 280,
  width: 500,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

interface Props {
  header: string;
  data?: any;
  select?: any;
  isShow: boolean;
  closeModal: () => void;
  applyHandler: () => void;
  updateTable: () => void;
}

export const ReportModal: React.FC<Props> = ({ isShow, header, data, updateTable, closeModal, select }) => {
  const dispatch = useDispatch();
  const { loadingUpdate, modalSuccessResponse } = useSelector(aggregationReportSelector);

  const hasFailedResponse = modalSuccessResponse?.messages?.Failed?.length > 0 ?? false;

  const doNotSendBtnDisabled =
    data?.severityLevel === 4 ||
    (data?.externalAggregationStatus !== 2 &&
      data?.externalAggregationStatus !== 4 &&
      data?.externalAggregationStatus !== 14);

  useEffect(() => {
    if (modalSuccessResponse?.messages?.Successful != null) {
      dispatch(putUpdateStatusSuccess({}));
      closeModal();
      updateTable();
    }
  }, [modalSuccessResponse]);

  const deaggregateHandler = () => {
    dispatch(putUpdateStatusRequest({ sscc: select === 2 ? data.sscc : data.palletSscc, deaggregate: true }));
  };

  const doNotSendHandler = () => {
    dispatch(putUpdateStatusRequest({ sscc: select === 2 ? data.sscc : data.palletSscc, doNotSend: true }));
  };

  return (
    <>
      {data ? (
        <Modal
          open={isShow}
          onClose={() => {
            closeModal();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styles.spinnerWrapper}>{loadingUpdate && <Spinner />}</div>
            <div className={styles.headerButtonsWrapper}>
              <CloseIcon onClick={closeModal} />
            </div>

            <span className={styles.header}>{header}</span>

            <div className={styles.buttonWrapper}>
              <Button
                className={styles.btn}
                variant="contained"
                disabled={data.aggregationStatus !== 2}
                onClick={deaggregateHandler}
              >
                Деагрегувати
              </Button>
              <Button
                className={styles.btn}
                variant="contained"
                disabled={doNotSendBtnDisabled}
                onClick={doNotSendHandler}
              >
                Зняти з фонових процесів
              </Button>
            </div>
            {hasFailedResponse ? <span className={styles.errRes}>{modalSuccessResponse?.messages?.Failed}</span> : null}
          </Box>
        </Modal>
      ) : null}
    </>
  );
};
