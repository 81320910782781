import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AvailableCodesTable from '../../common/AvailableCodesTable';
import InputSearch from '../../common/InputSearch';
import styles from './AvailableCodes.module.scss';
import { getAllCodesRequest } from './services/actions';
import { availableCodesSelector } from './services/selector';
import { Spinner } from '../../common/Spinner/Spinner';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';

export const AvailableCodes: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { availableCodes, loading, getCodesErrorResponses, postAllowToPrintErrorResponses } =
    useSelector(availableCodesSelector);

  const [tableData, setTableData] = useState<any[]>(availableCodes);

  useEffect(() => {
    setTableData(availableCodes);
  }, [availableCodes]);

  useEffect(() => {
    dispatch(getAllCodesRequest());
  }, []);

  useEffect(() => {
    if (Object.keys(getCodesErrorResponses).length !== 0 && getCodesErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [getCodesErrorResponses]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getCodesErrorResponses).length !== 0 ? <ErrorResponse error={getCodesErrorResponses} /> : null}
      {Object.keys(postAllowToPrintErrorResponses).length !== 0 ? (
        <ErrorResponse error={postAllowToPrintErrorResponses} />
      ) : null}
      <section className={styles.codesHeader}>
        <div className={styles.loadingGroup}>
          <span className={styles.bankCodesSubTitle}>Банк кодів</span>
          {loading ? <Spinner /> : null}
        </div>
        <span className={styles.bankCodesAdditionalText}>
          Історія використання і залишки наявних кодів маркування для кожного продукту
        </span>
      </section>

      <section className={styles.block}>
        <div className={styles.searchBlock}>
          <span>Фільтр по внутрішній назві продукту:</span>
          <InputSearch setTableData={setTableData} data={availableCodes} />
        </div>
      </section>
      <AvailableCodesTable data={tableData} />
    </div>
  );
};
