export const CLOSE_ERROR_POPUP = 'CLOSE_ERROR_POPUP' as const;

export const GET_SERIES_REPORT_REQUEST = 'GET_SERIES_REPORT_REQUEST' as const;
export const GET_SERIES_REPORT_SUCCESS = 'GET_SERIES_REPORT_SUCCESS' as const;
export const GET_SERIES_REPORT_ERROR = 'GET_SERIES_REPORT_ERROR' as const;

export const GET_EXCEL_FILE_SERIES_REQUEST = 'GET_EXCEL_FILE_SERIES_REQUEST' as const;
export const GET_EXCEL_FILE_SERIES_SUCCESS = 'GET_EXCEL_FILE_SERIES_SUCCESS' as const;
export const GET_EXCEL_FILE_SERIES_ERROR = 'GET_EXCEL_FILE_SERIES_ERROR' as const;

export const POST_UNRESERVE_MARKING_CODES_SERIES_REQUEST = 'POST_UNRESERVE_MARKING_CODES_SERIES_REQUEST' as const;
export const POST_UNRESERVE_MARKING_CODES_SERIES_SUCCESS = 'POST_UNRESERVE_MARKING_CODES_SERIES_SUCCESS' as const;
export const POST_UNRESERVE_MARKING_CODES_SERIES_ERROR = 'POST_UNRESERVE_MARKING_CODES_SERIES_ERROR' as const;
