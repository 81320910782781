import { Box, Button, Modal } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';

import styles from './DownloadMobileGatewayModal.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600',
  height: '50%',
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  outline: 'none !important',
  border: 'none !important',
  p: 4,
};

interface Props {
  isShow: boolean;
  onCloseModal: () => void;
}
export const DownloadMobileGatewayModal: React.FC<Props> = ({ isShow, onCloseModal }) => {
  const searchString = process.env.REACT_APP_MOBILE_GATEWAY_DOWNLOAD_LINK;

  return (
    <Modal
      open={isShow}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <section className={styles.wrapper}>
          <span className={styles.title}>Зкачайте програму Mobile Gateway за посиланням або за допомогою QR-коду:</span>
          <a className={styles.link} href={searchString}>
            {searchString}
          </a>
          <QRCodeSVG
            value={`${searchString}`}
            size={128}
            bgColor={'#ECEFF1'}
            fgColor={'#000000'}
            includeMargin={false}
          />
          <Button variant="contained" className={styles.btn} onClick={onCloseModal}>
            <span className={styles.btnText}>OK</span>
          </Button>
        </section>
      </Box>
    </Modal>
  );
};
