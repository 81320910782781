import { Actions } from './actions';
import {
  CLOSE_ERROR_POPUP,
  GET_SERIES_REPORT_REQUEST,
  GET_SERIES_REPORT_SUCCESS,
  GET_SERIES_REPORT_ERROR,
  GET_EXCEL_FILE_SERIES_REQUEST,
  GET_EXCEL_FILE_SERIES_SUCCESS,
  GET_EXCEL_FILE_SERIES_ERROR,
  POST_UNRESERVE_MARKING_CODES_SERIES_REQUEST,
  POST_UNRESERVE_MARKING_CODES_SERIES_SUCCESS,
  POST_UNRESERVE_MARKING_CODES_SERIES_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  innerModalLoading: false as boolean,
  error: null as Error | null,

  seriesReportResponse: [] as any,
  seriesReportErrorResponses: {} as any,

  postUnreserveMarkingCodesErrorResponse: {} as any,
  postUnreserveMarkingCodes: '' as string,
};

export type SeriesReportState = typeof initialState;

export const SeriesReportReducer = (state: SeriesReportState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_SERIES_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        seriesReportResponse: [],
        seriesReportErrorResponses: {},
      };
    }
    case GET_SERIES_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        seriesReportResponse: action.payload,
      };
    }
    case GET_SERIES_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        seriesReportErrorResponses: action.payload,
      };
    }
    case GET_EXCEL_FILE_SERIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EXCEL_FILE_SERIES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_EXCEL_FILE_SERIES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_UNRESERVE_MARKING_CODES_SERIES_REQUEST: {
      return {
        ...state,
        innerModalLoading: true,
        postUnreserveMarkingCodes: '',
      };
    }
    case POST_UNRESERVE_MARKING_CODES_SERIES_SUCCESS: {
      return {
        ...state,
        innerModalLoading: false,
        postUnreserveMarkingCodes: action.payload,
      };
    }
    case POST_UNRESERVE_MARKING_CODES_SERIES_ERROR: {
      return {
        ...state,
        innerModalLoading: false,
        postUnreserveMarkingCodesErrorResponse: action.payload,
      };
    }
    case CLOSE_ERROR_POPUP: {
      return {
        ...state,
        loading: false,
        seriesReportErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
