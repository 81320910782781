import { DataGrid, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import { style } from '../DataTable/style';

interface Props {
  data: any;
}

export const WithdrawalReportTable: React.FC<Props> = ({ data }) => {
  const [pageSize, setPageSize] = React.useState(50);

  const seriesGrid = [
    { field: 'id', headerName: 'Ідентифікатор', width: 120 },
    { field: 'gtin', headerName: 'Код GTIN', width: 130 },
    { field: 'productInternalName', headerName: 'Внутрішня назва продукції', width: 240 },
    { field: 'series', headerName: 'Серія', width: 110 },
    { field: 'serialNumber', headerName: 'Серійний номер', width: 160 },
    { field: 'reasonId', headerName: 'Код причини', width: 100 },
    { field: 'reason', headerName: 'Причина', width: 240 },
    { field: 'comment', headerName: 'Коментар', minWidth: 200, flex: 1 },
    { field: 'eventDate', headerName: 'Дата і час', width: 200 },
    { field: 'initiatorEmail', headerName: 'Користувач', width: 240 },
  ];

  return (
    <div style={{ height: 580, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={34}
        rowHeight={34}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        columns={seriesGrid}
        getRowId={() => Math.random()}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};
