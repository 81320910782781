import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Box, Button, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SimpleField from '../SimpleField';
import { useTranslation } from 'react-i18next';
import styles from './AddPackModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { putUpdatePackingRequest, putUpdatePackingSuccess } from '../../Handbook/Packaging/services/actions';
import { packingSelector } from '../../Handbook/Packaging/services/selector';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface Props {
  isShown: boolean;
  closeModal: () => void;
}

export const AddPackModal: React.FC<Props> = ({ isShown, closeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newPack, updatePackResponse }: any = useSelector(packingSelector);

  const onSaveHandler = (values: any) => {
    const dataToSet = { ...values, weight: values.weight.trim(), name: values.name.trim() };
    dispatch(putUpdatePackingRequest(dataToSet));
  };

  const initialValue = {
    id: newPack?.id,
    weight: '',
    name: '',
  };

  useEffect(() => {
    if (Object.keys(updatePackResponse).length !== 0 && updatePackResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updatePackResponse]);

  return (
    <div>
      {Object.keys(newPack).length !== 0 ? (
        <Modal
          open={isShown}
          onClose={() => {
            dispatch(putUpdatePackingSuccess({}));
            closeModal();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styles.menu}>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                {t('CreateMasterDataEntry')}
              </Typography>
              <CloseIcon
                className={styles.icon}
                onClick={() => {
                  dispatch(putUpdatePackingSuccess({}));
                  closeModal();
                }}
              />
            </div>
            <Formik initialValues={initialValue} onSubmit={() => undefined} enableReinitialize>
              {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className={styles.header}>
                    <div className={styles.wrapper}>
                      <span className={styles.title}>
                        Найменування (ID {values.id}) <em className={styles.required}>*</em>
                      </span>
                      <SimpleField
                        name="name"
                        hasMarginTop={false}
                        maxLength={250}
                        validate={true}
                        value={values.name}
                      />
                    </div>
                    <div className={styles.wrapper}>
                      <span className={styles.title}>
                        Вага пакування, гр <em className={styles.required}>*</em>
                      </span>
                      <SimpleField
                        name="weight"
                        size="small"
                        hasMarginTop={false}
                        maxLength={6}
                        type="number"
                        validate={true}
                        value={values.weight}
                      />
                    </div>
                  </div>
                  <section className={styles.btnSection}>
                    <>
                      {Object.keys(updatePackResponse).length !== 0 && updatePackResponse?.messages?.Failed !== null ? (
                        <span className={styles.errorMessage}>{updatePackResponse?.messages?.Failed[0]}</span>
                      ) : (
                        <div> </div>
                      )}
                    </>
                    <div>
                      <Button
                        className={styles.saveBtn}
                        disabled={values.name.trim().length === 0 || values.weight.trim().length === 0}
                        variant="contained"
                        onClick={() => onSaveHandler(values)}
                      >
                        <span className={styles.btnText}>Зберегти</span>
                        <SaveIcon className={styles.icon} />
                      </Button>
                    </div>
                  </section>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
};
