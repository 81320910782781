import React, { useEffect, useState } from 'react';
import { Modal, Box, CardMedia, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SaveIcon from '@mui/icons-material/Save';
import styles from './ProductImageModal.module.scss';
import { useDispatch } from 'react-redux';
import { putAddProductImageRequest } from '../../Handbook/Products/services/actions';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '900',
  height: '90%',
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface Props {
  isShownViewer: boolean;
  closeViewerModal: () => void;
  img?: any;
  productId?: number;
}

export const ProductImageModal: React.FC<Props> = ({ isShownViewer, closeViewerModal, img, productId }) => {
  const [file, setFile] = useState<any>();
  const [fileToShow, setFileToShow] = useState<any>();
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    setFile(e.target.files[0]);
    setFileToShow(URL.createObjectURL(e.target.files[0]));
  };

  const onSaveHandler = () => {
    dispatch(putAddProductImageRequest({ productId, file }));
    closeViewerModal();
  };

  useEffect(() => {
    setFileToShow(img);
  }, [img]);

  return (
    <div>
      <Modal
        open={isShownViewer}
        onClose={closeViewerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.header}>
            <CloseIcon className={styles.icon} onClick={closeViewerModal} />
          </div>
          <CardMedia component="img" image={fileToShow} sx={{ objectFit: 'contain', width: '100%', height: '80%' }} />
          <section className={styles.btnGroup}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="product-button-file"
              multiple
              type="file"
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="product-button-file">
              <Button className={styles.btn} variant="contained" component="span">
                <span className={styles.btnText}>Вибрати</span>
                <AddAPhotoIcon className={styles.icon} />
              </Button>
            </label>
            <Button className={styles.btn} variant="contained" onClick={onSaveHandler} disabled={!file}>
              <span className={styles.btnText}>Зберегти</span>
              <SaveIcon className={styles.icon} />
            </Button>
          </section>
        </Box>
      </Modal>
    </div>
  );
};
