import { AxiosResponse } from 'axios';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { Reports } from '../../../../entities/Reports/service';
import { GET_DOWNLOAD_REPORT_REQUEST, GET_TEXT_REPORT_REQUEST, GET_TREE_REPORT_REQUEST } from './constants';
import {
  getDownloadReportError,
  getDownloadReportRequest,
  getDownloadReportSuccess,
  getTextReportError,
  getTextReportRequest,
  getTextReportSuccess,
  getTreeReportError,
  getTreeReportRequest,
  getTreeReportSuccess,
} from './actions';

function* getTextReportRequestSaga(action: ReturnType<typeof getTextReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getTextReport, action.payload);
    const res = response.data.ResponseBody;

    if (res.length === 0) {
      yield put(getTextReportSuccess(['Не знайдено.']));
    } else {
      const zeroSection = res.find((level: any) => level.section === 0 && level.reportPhoto);
      const indexOfZeroSection = res.findIndex((level: any) => level.section === 0);

      if (zeroSection) {
        const photo: AxiosResponse = yield call(Reports.getPhoto, zeroSection.reportPhoto);
        const image = URL.createObjectURL(photo.data);
        res[indexOfZeroSection].reportPhoto = image;
        yield put(getTextReportSuccess(res));
      }

      yield put(getTextReportSuccess(res));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getTextReportError(error));
  }
}

function* getTreeReportRequestSaga(action: ReturnType<typeof getTreeReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getTreeReport, action.payload);
    const res = response.data.ResponseBody;

    if (response.status === 204) {
      yield put(
        getTreeReportError({
          message: 'серійний номер відсутній в системі.',
          name: ' ',
          config: {
            url: '/Report/Graph/Tree/ByAny',
          },
          status: 204,
        }),
      );
    } else {
      yield put(getTreeReportSuccess(res));
    }

    yield delay(8000);
    yield put(getTreeReportError({}));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getTreeReportError(error));
  }
}

function* getDownloadReportRequestSaga(action: ReturnType<typeof getDownloadReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.downloadReport, action.payload);

    if (response.status === 204) {
      yield put(
        getDownloadReportError({
          message: 'по документу не відвантажувались позиції для даної країни.',
          name: ' ',
          config: {
            url: '/Blob/Download',
          },
          status: 204,
        }),
      );
    } else {
      yield put(getDownloadReportSuccess());
    }

    yield delay(8000);
    yield put(getDownloadReportError({}));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getDownloadReportError(error));
  }
}

export function* SearchCodesSagaWatcher() {
  yield takeLatest(GET_TEXT_REPORT_REQUEST, getTextReportRequestSaga);
  yield takeLatest(GET_TREE_REPORT_REQUEST, getTreeReportRequestSaga);
  yield takeLatest(GET_DOWNLOAD_REPORT_REQUEST, getDownloadReportRequestSaga);
}
