import React, { useEffect } from 'react';
import { Box, Modal, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';

import styles from './AdditionalDataModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { postDetailedInfoRequest } from '../services/actions';
import { availableCodesSelector } from '../services/selector';
import { ModalSpinner } from '../../../common/ModalSpinner/ModalSpinner';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 330,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  p: 4,
};

interface Props {
  isShown: boolean;
  closeModal: () => void;
  params: any;
}

export const AdditionalDataModal = ({ isShown, closeModal, params }: Props) => {
  const dispatch = useDispatch();
  const { modalLoading, postDetailedInfoResponse } = useSelector(availableCodesSelector);

  useEffect(() => {
    if (isShown) {
      dispatch(postDetailedInfoRequest(params));
    }
  }, [isShown, params]);

  return (
    <div>
      <Modal
        open={isShown}
        onClose={() => {
          closeModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.spinnerWrapper}>{modalLoading ? <ModalSpinner /> : null}</div>
          <div className={styles.buttonsWrapper}>
            <RefreshIcon onClick={() => dispatch(postDetailedInfoRequest(params))} />
            <CloseIcon onClick={closeModal} />
          </div>
          <div className={styles.table}>
            <div className={styles.firstColumn}>
              <span>Внутрішня назва продукції:</span>
              <span>GTIN:</span>
              <span>Країна:</span>
              <span></span>
              <span></span>
              <span></span>
              <span>Імпортовано за весь час, шт:</span>
              <span>Доступно до друку, шт:</span>
              <span>Заброньовано завданнями друку, шт:</span>
            </div>
            <div className={styles.column}>
              {postDetailedInfoResponse?.internalName?.length > 70 ? (
                <span>
                  {postDetailedInfoResponse?.internalName?.substring(0, 70)}
                  <Tooltip title={postDetailedInfoResponse.internalName} placement="right">
                    <span> ...</span>
                  </Tooltip>
                </span>
              ) : (
                <span>{postDetailedInfoResponse.internalName}</span>
              )}
              <span>{postDetailedInfoResponse.EAN13}</span>
              <span>{postDetailedInfoResponse.countryName}</span>
              <span></span>
              <span></span>
              <span></span>
              <span>{postDetailedInfoResponse.importedCount ?? ' '}</span>
              <span>{postDetailedInfoResponse.allowedToPrint ?? ' '}</span>
              <span>{postDetailedInfoResponse.bookedForPrinter ?? ' '}</span>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
