export const GET_PRODUCT_BY_GTIN_REQUEST = 'GET_PRODUCT_BY_GTIN_REQUEST' as const;
export const GET_PRODUCT_BY_GTIN_SUCCESS = 'GET_PRODUCT_BY_GTIN_SUCCESS' as const;
export const GET_PRODUCT_BY_GTIN_ERROR = 'GET_PRODUCT_BY_GTIN_ERROR' as const;

export const POST_CREATE_PRODUCT_REQUEST = 'POST_CREATE_PRODUCT_REQUEST' as const;
export const POST_CREATE_PRODUCT_SUCCESS = 'POST_CREATE_PRODUCT_SUCCESS' as const;
export const POST_CREATE_PRODUCT_ERROR = 'POST_CREATE_PRODUCT_ERROR' as const;

export const PUT_UPDATE_PRODUCT_REQUEST = 'PUT_UPDATE_PRODUCT_REQUEST' as const;
export const PUT_UPDATE_PRODUCT_SUCCESS = 'PUT_UPDATE_PRODUCT_SUCCESS' as const;
export const PUT_UPDATE_PRODUCT_ERROR = 'PUT_UPDATE_PRODUCT_ERROR' as const;

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST' as const;
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS' as const;
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR' as const;

export const GET_PHOTO_REQUEST = 'GET_PHOTO_REQUEST' as const;
export const GET_PHOTO_SUCCESS = 'GET_PHOTO_SUCCESS' as const;
export const GET_PHOTO_ERROR = 'GET_PHOTO_ERROR' as const;

export const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST' as const;
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS' as const;
export const GET_ALL_PRODUCTS_ERROR = 'GET_ALL_PRODUCTS_ERROR' as const;

export const PUT_ADD_PRODUCT_IMAGE_REQUEST = 'PUT_ADD_PRODUCT_IMAGE_REQUEST' as const;
export const PUT_ADD_PRODUCT_IMAGE_SUCCESS = 'PUT_ADD_PRODUCT_IMAGE_SUCCESS' as const;
export const PUT_ADD_PRODUCT_IMAGE_ERROR = 'PUT_ADD_PRODUCT_IMAGE_ERROR' as const;

export const DELETE_PRODUCT_IMAGE_REQUEST = 'DELETE_PRODUCT_IMAGE_REQUEST' as const;
export const DELETE_PRODUCT_IMAGE_SUCCESS = 'DELETE_PRODUCT_IMAGE_SUCCESS' as const;
export const DELETE_PRODUCT_IMAGE_ERROR = 'DELETE_PRODUCT_IMAGE_ERROR' as const;

export const POST_PRINT_PRODUCT_PROFILE_REQUEST = 'POST_PRINT_PRODUCT_PROFILE_REQUEST' as const;
export const POST_PRINT_PRODUCT_PROFILE_SUCCESS = 'POST_PRINT_PRODUCT_PROFILE_SUCCESS' as const;
export const POST_PRINT_PRODUCT_PROFILE_ERROR = 'POST_PRINT_PRODUCT_PROFILE_ERROR' as const;

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE' as const;
export const CLOSE_ERROR_POPUP_PRODUCTS = 'CLOSE_ERROR_POPUP_PRODUCTS' as const;
