import { AxiosResponse } from 'axios';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { Products } from '../../../../entities/Products/service';
import {
  deleteProductError,
  deleteProductRequest,
  deleteProductSuccess,
  getAllProductsError,
  getAllProductsSuccess,
  getAllProductsRequest,
  getPhotoError,
  getPhotoRequest,
  getPhotoSuccess,
  getProductsByGtinError,
  getProductsByGtinRequest,
  getProductsByGtinSuccess,
  postCreateProductError,
  postCreateProductRequest,
  postCreateProductSuccess,
  putUpdateProductError,
  putUpdateProductRequest,
  putUpdateProductSuccess,
  setErrorMessage,
  putAddProductImageRequest,
  putAddProductImageError,
  deleteProductImageRequest,
  deleteProductImageError,
  putAddProductImageSuccess,
  postPrintProductProfileRequest,
  postPrintProductProfileSuccess,
  postPrintProductProfileError,
} from './actions';
import {
  DELETE_PRODUCT_IMAGE_REQUEST,
  DELETE_PRODUCT_REQUEST,
  GET_ALL_PRODUCTS_REQUEST,
  GET_PHOTO_REQUEST,
  GET_PRODUCT_BY_GTIN_REQUEST,
  POST_CREATE_PRODUCT_REQUEST,
  POST_PRINT_PRODUCT_PROFILE_REQUEST,
  PUT_ADD_PRODUCT_IMAGE_REQUEST,
  PUT_UPDATE_PRODUCT_REQUEST,
} from './constants';
import { objToFormData } from '../../../../utils';

function* getProductByGtinSaga(action: ReturnType<typeof getProductsByGtinRequest>) {
  try {
    const response: AxiosResponse = yield call(Products.getProductByGtin, action.payload);
    const res = response.data.ResponseBody;
    if (res.messages?.Successful !== null) {
      // @ts-expect-error
      const photo = yield call(Products.getPhoto, res.imagePath);
      const image = URL.createObjectURL(photo.data);
      yield put(getProductsByGtinSuccess({ ...res, imagePath: image }));
    } else {
      yield put(setErrorMessage(res.messages?.Failed));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getProductsByGtinError(error));
  }
}

function* postCreateProductSaga(action: ReturnType<typeof postCreateProductRequest>) {
  try {
    const response: AxiosResponse = yield call(Products.createProduct, action.payload);
    const res = response.data.ResponseBody;
    if (res.messages?.Successful !== null) {
      yield put(postCreateProductSuccess(res.messages?.Successful));
      action.navigate(`/product?${res.messages?.Successful.gtin}`);
    } else {
      yield put(setErrorMessage(res.messages?.Failed));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCreateProductError(error));
  }
}

function* putUpdateProductSaga(action: ReturnType<typeof putUpdateProductRequest>) {
  try {
    // @ts-expect-error
    const response: any = yield call(Products.updateProduct, action.payload);
    if (response.data.Result === 0) {
      yield put(putUpdateProductSuccess(response.data));
      yield put(getProductsByGtinRequest({ gtin: action.payload.gtin }));
    } else {
      const res = response.data.ResponseBody;
      yield put(putUpdateProductSuccess(res));
      yield put(getProductsByGtinRequest({ gtin: action.payload.gtin }));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdateProductError(error));
  }
}

function* getPhotoSaga(action: ReturnType<typeof getPhotoRequest>) {
  try {
    const response: AxiosResponse = yield call(Products.getPhoto, action.payload);
    const res = URL.createObjectURL(response.data);
    yield put(getPhotoSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPhotoError(error));
  }
}

function* addPhotoSaga(action: ReturnType<typeof putAddProductImageRequest>) {
  try {
    const body = objToFormData(action.payload);
    // @ts-expect-error
    const response = yield call(Products.addProductImage, body);
    // @ts-expect-error
    const photo = yield call(Products.getPhoto, response.data.ResponseBody.imagePath);
    const image = URL.createObjectURL(photo.data);
    yield put(putAddProductImageSuccess(image));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putAddProductImageError(error));
  }
}

function* deletePhotoSaga(action: ReturnType<typeof deleteProductImageRequest>) {
  try {
    yield call(Products.deleteProductImage, action.payload);
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteProductImageError(error));
  }
}

function* deleteProductSaga(action: ReturnType<typeof deleteProductRequest>) {
  try {
    const response: AxiosResponse = yield call(Products.deleteProduct, action.payload);
    const res = response.data.ResponseBody;
    yield put(deleteProductSuccess(res));
    yield put(getAllProductsRequest());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteProductError(error));
  }
}

function* getAllProductsSaga() {
  try {
    const response: AxiosResponse = yield call(Products.getProductList);
    const res = response.data.ResponseBody;
    yield put(getAllProductsSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAllProductsError(error));
  }
}

function* postPrintProductProfileRequestSaga(action: ReturnType<typeof postPrintProductProfileRequest>) {
  try {
    const response: AxiosResponse = yield call(Products.downloadProductProfile, action.payload);

    if (response.status === 204) {
      yield put(
        postPrintProductProfileError({
          message: 'Виникла помилка',
          name: ' ',
          config: {
            url: '/Blob/Download',
          },
          status: 204,
        }),
      );
    } else {
      yield put(postPrintProductProfileSuccess(response));
    }

    yield delay(8000);
    yield put(postPrintProductProfileError({}));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postPrintProductProfileError(error));
  }
}

export function* ProductSagaWatcher() {
  yield takeLatest(DELETE_PRODUCT_REQUEST, deleteProductSaga);
  yield takeLatest(GET_PHOTO_REQUEST, getPhotoSaga);
  yield takeLatest(PUT_ADD_PRODUCT_IMAGE_REQUEST, addPhotoSaga);
  yield takeLatest(DELETE_PRODUCT_IMAGE_REQUEST, deletePhotoSaga);
  yield takeLatest(PUT_UPDATE_PRODUCT_REQUEST, putUpdateProductSaga);
  yield takeLatest(GET_PRODUCT_BY_GTIN_REQUEST, getProductByGtinSaga);
  yield takeLatest(POST_CREATE_PRODUCT_REQUEST, postCreateProductSaga);
  yield takeLatest(GET_ALL_PRODUCTS_REQUEST, getAllProductsSaga);
  yield takeLatest(POST_PRINT_PRODUCT_PROFILE_REQUEST, postPrintProductProfileRequestSaga);
}
