import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import { style } from './style';
import { Tooltip } from '@mui/material';
import styles from './ReceiptsTable.module.scss';
import { DeactivateModal } from '../DeactivateModal';
import useModal from '../../../../shared/hooks/useModal/useModal';
import { deleteDeactivateRequest, getReceiptPDFFileRequest } from '../../services/actions';
import { useDispatch } from 'react-redux';
import Finished from '../../../../../icons/check_circle_done.svg';
import Running from '../../../../../icons/run_circle_green.svg';

interface Props {
  data: any;
  updateTable: () => void;
}

export const ReceiptsTable: React.FC<Props> = ({ data, updateTable }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(15);
  const [selectedReceipt, setSelectedReceipt] = React.useState<any>(null);
  const { isShow: isShowDeactivate, openModal: openDeactivate, closeModal: closeDeactivate } = useModal();

  const deactivateHandler = () => {
    dispatch(deleteDeactivateRequest({ receiptId: selectedReceipt.id }));
  };

  return (
    <div style={{ height: 599, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        getRowId={() => Math.random()}
        headerHeight={34}
        rowHeight={34}
        disableSelectionOnClick
        columns={[
          {
            field: 'isActive',
            headerName: 'Статус',
            headerAlign: 'center',
            align: 'center',
            width: 70,
            renderCell: (params: GridRenderCellParams<Date>) => (
              <span>
                {params.row.isActive ? (
                  <div className={styles.customCell}>
                    <Tooltip title="Актуальний" placement="top">
                      <img src={Running} alt="" />
                    </Tooltip>
                  </div>
                ) : (
                  <div className={styles.customCell}>
                    <Tooltip title="Не активний" placement="top">
                      <img src={Finished} alt="" />
                    </Tooltip>
                  </div>
                )}
              </span>
            ),
          },
          { field: 'id', headerName: 'Рецепт №', width: 85 },
          { field: 'countryCode', headerName: 'Країна', width: 85 },
          { field: 'createDate', headerName: 'Дата створення', width: 140 },
          { field: 'gtin', headerName: 'Код GTIN', width: 130 },
          { field: 'productInternalName', headerName: 'Внутрішня назва продукції', minWidth: 330, flex: 1 },
          { field: 'series', headerName: 'Серія', width: 150 },
          { field: 'productionLineId', headerName: 'Виробнича лінія', width: 100 },
          { field: 'mrp', headerName: 'МРЦ', width: 80 },
          { field: 'expirationDate', headerName: 'Спожити до (упaковка)', width: 125 },
          { field: 'aI17', headerName: 'Спожити до (AI17)', width: 125 },
          { field: 'mfDate', headerName: 'Дата виробництва', width: 125 },
          { field: 'quantityToPrint', headerName: 'Надрукувати', width: 105 },
          { field: 'printedCount', headerName: 'Надруковано', width: 105 },
          {
            field: 'button',
            headerName: 'Меню',
            headerAlign: 'center',
            width: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <>
                <Tooltip title="Скачати в форматі PDF" placement="top">
                  <a
                    onClick={() =>
                      dispatch(
                        getReceiptPDFFileRequest({
                          receiptId: params.row.id,
                        }),
                      )
                    }
                  >
                    <DownloadIcon className={styles.icon} />
                  </a>
                </Tooltip>
                <Tooltip title="Додаткові функції" placement="top">
                  <MenuIcon
                    className={styles.icon}
                    onClick={() => {
                      setSelectedReceipt(params.row);
                      openDeactivate();
                    }}
                  />
                </Tooltip>
              </>
            ),
          },
        ]}
        sx={style}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        disableColumnMenu
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />

      {selectedReceipt && (
        <DeactivateModal
          isShow={isShowDeactivate}
          closeModal={closeDeactivate}
          header={`Зміна статусу рецепта ${selectedReceipt.id}`}
          updateTable={updateTable}
          deactivateHandler={deactivateHandler}
          resetSelected={() => setSelectedReceipt(null)}
          isDeactivated={!selectedReceipt.isActive}
        />
      )}
    </div>
  );
};
