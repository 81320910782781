import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { invoiceReportSelector } from '../../services/selector';
import { getDetailedExcelReportInvoicesRequest } from '../../services/actions';
import { Spinner } from '../../../../common/Spinner/Spinner';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface IReprintModalProps {
  isShown: boolean;
  params: any;
  closeModal: () => void;
}

export const InvoiceDetailsModal = ({ isShown, closeModal, params }: IReprintModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalLoading } = useSelector(invoiceReportSelector);

  const downloadDetailedReport = (reportType: any) => {
    dispatch(getDetailedExcelReportInvoicesRequest({ ...params, reportType }));
  };

  return (
    <Modal
      open={isShown}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <span className={styles.responseText}>{''}</span>
        <div className={styles.spinnerWrapper}>{modalLoading && <Spinner />}</div>
        <div className={styles.buttonsWrapper}>
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        </div>
        <div className={styles.wrapper}>
          <Button variant="contained" className={styles.btn} onClick={() => downloadDetailedReport(1)}>
            {t('Специфікація вантажу')}
          </Button>
          <Button variant="contained" className={styles.btn} onClick={() => downloadDetailedReport(2)}>
            {t('Упаковочний лист')}
          </Button>
          <Button variant="contained" className={styles.btn} onClick={() => downloadDetailedReport(3)}>
            {t("Звіт 'АИК' формату TURON, Узбекистан")}
          </Button>
          <Button variant="contained" className={styles.btn} onClick={() => downloadDetailedReport(4)}>
            {t('Звіт формату NBD.MED, Киргизія')}
          </Button>
          <Button variant="contained" className={styles.btn} onClick={() => downloadDetailedReport(5)}>
            {t("Звіт формату  ЦЭДМ', Казахстан")}
          </Button>
          <Button variant="contained" className={styles.btn} onClick={() => downloadDetailedReport(6)}>
            {t('Звіт формату DRAP, Пакистан')}
          </Button>
          <Button variant="contained" className={styles.btn} onClick={() => downloadDetailedReport(7)}>
            {t('Звіт RASD формату SFDA, Саудівська Аравія')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
