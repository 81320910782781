import {
  CLOSE_ERROR_POPUP_PRODUCTS,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_IMAGE_ERROR,
  DELETE_PRODUCT_IMAGE_REQUEST,
  DELETE_PRODUCT_IMAGE_SUCCESS,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS_ERROR,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_PHOTO_ERROR,
  GET_PHOTO_REQUEST,
  GET_PHOTO_SUCCESS,
  GET_PRODUCT_BY_GTIN_ERROR,
  GET_PRODUCT_BY_GTIN_REQUEST,
  GET_PRODUCT_BY_GTIN_SUCCESS,
  POST_CREATE_PRODUCT_ERROR,
  POST_CREATE_PRODUCT_REQUEST,
  POST_CREATE_PRODUCT_SUCCESS,
  POST_PRINT_PRODUCT_PROFILE_ERROR,
  POST_PRINT_PRODUCT_PROFILE_REQUEST,
  POST_PRINT_PRODUCT_PROFILE_SUCCESS,
  PUT_ADD_PRODUCT_IMAGE_ERROR,
  PUT_ADD_PRODUCT_IMAGE_REQUEST,
  PUT_ADD_PRODUCT_IMAGE_SUCCESS,
  PUT_UPDATE_PRODUCT_ERROR,
  PUT_UPDATE_PRODUCT_REQUEST,
  PUT_UPDATE_PRODUCT_SUCCESS,
  SET_ERROR_MESSAGE,
} from './constants';

export const getProductsByGtinRequest = (body: any) => ({
  type: GET_PRODUCT_BY_GTIN_REQUEST,
  payload: body,
});
export const getProductsByGtinSuccess = (data: any) => ({
  type: GET_PRODUCT_BY_GTIN_SUCCESS,
  payload: data,
});
export const getProductsByGtinError = (error: any) => ({
  type: GET_PRODUCT_BY_GTIN_ERROR,
  payload: error,
});

export const postCreateProductRequest = (body: any, navigate?: any) => ({
  type: POST_CREATE_PRODUCT_REQUEST,
  payload: body,
  navigate,
});
export const postCreateProductSuccess = (data: any) => ({
  type: POST_CREATE_PRODUCT_SUCCESS,
  payload: data,
});
export const postCreateProductError = (error: any) => ({
  type: POST_CREATE_PRODUCT_ERROR,
  payload: error,
});

export const putUpdateProductRequest = (body: any) => ({
  type: PUT_UPDATE_PRODUCT_REQUEST,
  payload: body,
});
export const putUpdateProductSuccess = (data: any) => ({
  type: PUT_UPDATE_PRODUCT_SUCCESS,
  payload: data,
});
export const putUpdateProductError = (error: any) => ({
  type: PUT_UPDATE_PRODUCT_ERROR,
  payload: error,
});

export const getPhotoRequest = (body: any) => ({
  type: GET_PHOTO_REQUEST,
  payload: body,
});
export const getPhotoSuccess = (data: any) => ({
  type: GET_PHOTO_SUCCESS,
  payload: data,
});
export const getPhotoError = (error: any) => ({
  type: GET_PHOTO_ERROR,
  payload: error,
});

export const deleteProductRequest = (body: any) => ({
  type: DELETE_PRODUCT_REQUEST,
  payload: body,
});
export const deleteProductSuccess = (data: any) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: data,
});
export const deleteProductError = (error: any) => ({
  type: DELETE_PRODUCT_ERROR,
  payload: error,
});

export const getAllProductsRequest = () => ({
  type: GET_ALL_PRODUCTS_REQUEST,
});
export const getAllProductsSuccess = (data: any) => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload: data,
});
export const getAllProductsError = (error: any) => ({
  type: GET_ALL_PRODUCTS_ERROR,
  payload: error,
});

export const putAddProductImageRequest = (body: any) => ({
  type: PUT_ADD_PRODUCT_IMAGE_REQUEST,
  payload: body,
});
export const putAddProductImageSuccess = (data: any) => ({
  type: PUT_ADD_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});
export const putAddProductImageError = (error: any) => ({
  type: PUT_ADD_PRODUCT_IMAGE_ERROR,
  payload: error,
});

export const deleteProductImageRequest = (body: any) => ({
  type: DELETE_PRODUCT_IMAGE_REQUEST,
  payload: body,
});
export const deleteProductImageSuccess = (data: any) => ({
  type: DELETE_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});
export const deleteProductImageError = (error: any) => ({
  type: DELETE_PRODUCT_IMAGE_ERROR,
  payload: error,
});

export const postPrintProductProfileRequest = (body: any) => ({
  type: POST_PRINT_PRODUCT_PROFILE_REQUEST,
  payload: body,
});
export const postPrintProductProfileSuccess = (data: any) => ({
  type: POST_PRINT_PRODUCT_PROFILE_SUCCESS,
  payload: data,
});
export const postPrintProductProfileError = (error: any) => ({
  type: POST_PRINT_PRODUCT_PROFILE_ERROR,
  payload: error,
});

export const setErrorMessage = (error: any) => ({
  type: SET_ERROR_MESSAGE,
  payload: error,
});

export const closeErrorPopUpProducts = () => ({
  type: CLOSE_ERROR_POPUP_PRODUCTS,
});

export type Actions =
  | ReturnType<typeof postPrintProductProfileRequest>
  | ReturnType<typeof postPrintProductProfileSuccess>
  | ReturnType<typeof postPrintProductProfileError>
  | ReturnType<typeof deleteProductImageRequest>
  | ReturnType<typeof deleteProductImageSuccess>
  | ReturnType<typeof deleteProductImageError>
  | ReturnType<typeof putAddProductImageRequest>
  | ReturnType<typeof putAddProductImageSuccess>
  | ReturnType<typeof putAddProductImageError>
  | ReturnType<typeof closeErrorPopUpProducts>
  | ReturnType<typeof getAllProductsRequest>
  | ReturnType<typeof getAllProductsSuccess>
  | ReturnType<typeof getAllProductsError>
  | ReturnType<typeof deleteProductRequest>
  | ReturnType<typeof deleteProductSuccess>
  | ReturnType<typeof deleteProductError>
  | ReturnType<typeof setErrorMessage>
  | ReturnType<typeof getPhotoRequest>
  | ReturnType<typeof getPhotoSuccess>
  | ReturnType<typeof getPhotoError>
  | ReturnType<typeof putUpdateProductRequest>
  | ReturnType<typeof putUpdateProductSuccess>
  | ReturnType<typeof putUpdateProductError>
  | ReturnType<typeof postCreateProductRequest>
  | ReturnType<typeof postCreateProductSuccess>
  | ReturnType<typeof postCreateProductError>
  | ReturnType<typeof getProductsByGtinRequest>
  | ReturnType<typeof getProductsByGtinSuccess>
  | ReturnType<typeof getProductsByGtinError>;
