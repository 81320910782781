import { Box, Button, Modal, OutlinedInput } from '@mui/material';
import React from 'react';
import styles from './AddSeriesModal.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

const searchStyle = {
  width: 200,
  height: 30,
  fontSize: '13px !important',
  marginRight: 20,
  margin: 0,
  bgcolor: '#ffffff',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
};

interface Props {
  isShown: boolean;
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  setDuplicateSeries: React.Dispatch<React.SetStateAction<any>>;
  closeModal: () => void;
  addHandler: () => void;
  internalName: any;
  duplicateSeries: string;
}

export const AddSeriesModal: React.FC<Props> = ({
  isShown,
  data,
  setData,
  closeModal,
  addHandler,
  internalName,
  duplicateSeries,
  setDuplicateSeries,
}) => {
  return (
    <Modal
      open={isShown}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <span>Введіть номер серії до продукту {internalName}.</span>
        <div className={styles.searchGroup}>
          <span className={styles.hint}>Серія №: </span>
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            sx={searchStyle}
            onChange={(event) => {
              setData(event.target.value);
              setDuplicateSeries('');
            }}
            value={data}
            inputProps={{ maxLength: 13 }}
          />
        </div>
        <span className={styles.error}>{duplicateSeries}</span>
        <div className={styles.btnGroup}>
          <Button className={styles.exitBtn} variant="contained" onClick={closeModal}>
            <span className={styles.btnText}>Відмінити</span>
          </Button>
          <Button className={styles.submitBtn} variant="contained" onClick={addHandler}>
            <span className={styles.btnText}>Підтвердити</span>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
