import DownloadIcon from '@mui/icons-material/Download';
import { Spinner } from '../../common/Spinner/Spinner';
import 'moment/locale/uk';
import React, { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InputSearch from '../../common/InputSearch';
import { calendarStyleEnabled, selectStyle } from './muiStyles/muiStyles';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '../../../icons/arrow_back.svg';
import ArrowForwardIcon from '../../../icons/arrow_forward.svg';

import styles from './Withdrawal.module.scss';
import WithdrawalReportTable from '../../common/WithdrawalReportTable';
import { useDispatch, useSelector } from 'react-redux';
import { getWithdrawalReportRequest, getGlobalWithdrawalExcelReportRequest } from './services/actions';
import { withdrawalReportSelector } from './services/selector';
import { getAllWithdrawalsRequest } from '../../Handbook/Withdrawal/services/actions';
import { withdrawalSelector } from '../../Handbook/Withdrawal/services/selector';

export const Withdrawal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let timeoutId: NodeJS.Timeout | null = null;

  const { t } = useTranslation();

  const [withdrawalType, setwithdrawalType] = useState<number>(0);
  const [calendarDate, setCalendarDate] = useState<any>(new Date());

  const firstRenderRef = useRef(true);

  const handleMonthChange = (increment: number) => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCalendarDate(newDate);
  };

  const changeDateCalendar = (calendarValue: any) => {
    const newDate = new Date(calendarValue);
    newDate.setMonth(newDate.getMonth());
    setCalendarDate(newDate);
  };

  const { loading, withdrawalReportResponse, withdrawalReportErrorResponses } = useSelector(withdrawalReportSelector);
  const { loading: withdrawalReasons, withdrawals } = useSelector(withdrawalSelector);

  const [tableData, setTableData] = useState<any[]>(withdrawalReportResponse);

  const showHandler = () => {
    dispatch(
      getWithdrawalReportRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
        reasonId: withdrawalType,
      }),
    );
  };

  const onExelDownload = () => {
    dispatch(
      getGlobalWithdrawalExcelReportRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
        reasonId: withdrawalType,
      }),
    );
  };

  useEffect(() => {
    if (Object.keys(withdrawalReportErrorResponses).length !== 0 && withdrawalReportErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [withdrawalReportErrorResponses, navigate]);

  useEffect(() => {
    dispatch(
      getWithdrawalReportRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
        reasonId: 0,
      }),
    );

    dispatch(getAllWithdrawalsRequest());
  }, []);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      timeoutId = setTimeout(() => {
        showHandler();
        timeoutId = null;
      }, 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [calendarDate]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(withdrawalReportErrorResponses).length !== 0 ? (
        <ErrorResponse error={withdrawalReportErrorResponses} />
      ) : null}
      <div className={styles.loadingGroup}>
        <span className={styles.title}>{t('WithdrawalHeader')}</span>
        {loading || withdrawalReasons ? <Spinner /> : null}
      </div>
      <span>{t('WithdrawalHeaderShortDescription')}</span>
      <div className={styles.filterBlock}>
        <div className={styles.firstRow}>
          <div className={styles.calendarItem}>
            <span>Причина:</span>
            <Select
              sx={selectStyle}
              onChange={(e: SelectChangeEvent<any>) => {
                setwithdrawalType(e.target.value);
              }}
              value={withdrawalType}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={0}>
                Всі
              </MenuItem>
              {withdrawals.map((value: any) => (
                <MenuItem sx={{ fontSize: '13px' }} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.calendarItem}>
            <span>{t('SelectPeriod')}</span>
            <div className={styles.calendarRow}>
              <LocalizationProvider dateAdapter={AdapterMoment} locale={'uk'}>
                <DatePicker
                  inputFormat="MM/YYYY"
                  value={calendarDate}
                  views={['year', 'month']}
                  onChange={changeDateCalendar}
                  renderInput={(params) => <TextField sx={calendarStyleEnabled} {...params} />}
                  PaperProps={{
                    sx: {
                      '.MuiPickersCalendarHeader-label': {
                        fontSize: '13px',
                      },
                      '& .PrivatePickersYear-yearButton': {
                        fontSize: '13px',
                      },
                      '& .MuiTypography-root': {
                        fontSize: '13px',
                      },
                    },
                  }}
                />
                <Tooltip title="Місяць назад" placement="top">
                  <img src={ArrowBackIcon} onClick={() => handleMonthChange(-1)} alt="" />
                </Tooltip>
                <Tooltip title="Місяць вперед" placement="top">
                  <img src={ArrowForwardIcon} onClick={() => handleMonthChange(1)} alt="" />
                </Tooltip>
              </LocalizationProvider>
            </div>
          </div>
          <Button variant="contained" className={styles.btn} onClick={showHandler}>
            <span className={styles.btnText}>Пошук</span>
          </Button>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.searchGroup}>
            <span>{t('filterBy')}</span>
            <InputSearch setTableData={setTableData} data={withdrawalReportResponse} clearOnPropsChange={true} />
          </div>
          {tableData.length !== 0 ? (
            <div className={styles.downloadWrapper}>
              <span>Скачати таблицю в Excel</span>
              {!loading ? (
                <a onClick={onExelDownload}>
                  <DownloadIcon className={styles.downloadIcon} />
                </a>
              ) : (
                <DownloadIcon className={styles.downloadIcon} />
              )}
            </div>
          ) : null}
        </div>
      </div>
      <WithdrawalReportTable data={tableData} />
    </div>
  );
};
