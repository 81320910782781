import styles from './Reciepts.module.scss';
import { Spinner } from '../../common/Spinner/Spinner';
import React, { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import InputSearch from '../../common/InputSearch';
import { calendarStyleEnabled, calendarStyleDisabled, selectStyle } from './muiStyles/muiStyles';
import { useDispatch, useSelector } from 'react-redux';
import { receiptsSelector } from './services/selector';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import { disabledInputStyle, inputStyle } from './services/style';
import ArrowBackIcon from '../../../icons/arrow_back.svg';
import ArrowForwardIcon from '../../../icons/arrow_forward.svg';
import 'moment/locale/uk';
import { getProductListRequest, getReceiptsRequest, getReceiptsSuccess } from './services/actions';
import ReceiptsTable from './components/ReceiptsTable';
import { NewReceiptModal } from './components/NewReceiptModal';
import useModal from '../../shared/hooks/useModal/useModal';
import { getCountryListRequest } from '../../../entities/Countries/actions';
import { countryListSelector } from '../../../entities/Countries/selector';

export const Receipts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let timeoutId: NodeJS.Timeout | null = null;

  const [viewType, setViewType] = useState<number>(1);
  const [gtinValue, setGtinValue] = useState<any>('');
  const [seriesValue, setSeriesValue] = useState<any>('');
  const [calendarDate, setCalendarDate] = useState<any>(new Date());

  const firstRenderRef = useRef(true);

  const { isShow: isShowCreateNew, openModal: openCreateNew, closeModal: closeCreateNew } = useModal();

  const handleMonthChange = (increment: number) => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCalendarDate(newDate);
  };

  const changeDateCalendar = (calendarValue: any) => {
    const newDate = new Date(calendarValue);
    newDate.setMonth(newDate.getMonth());
    setCalendarDate(newDate);
  };

  const {
    loading,
    receiptsResponse,
    receiptsErrorResponses,
    deactivateErrorResponses,
    productListResponse,
    productListErrorResponse,
    createReceiptErrorResponse,
  } = useSelector(receiptsSelector);

  const { countries } = useSelector(countryListSelector);

  const [tableData, setTableData] = useState<any[]>(receiptsResponse);

  const showHandler = () => {
    if (viewType === 1) {
      dispatch(
        getReceiptsRequest({
          fromDate: moment(calendarDate).format('YYYY-MM-01'),
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
        }),
      );
    }

    if (viewType === 2) {
      dispatch(
        getReceiptsRequest({
          gtin: gtinValue,
        }),
      );
    }

    if (viewType === 3) {
      dispatch(
        getReceiptsRequest({
          series: seriesValue,
        }),
      );
    }
  };

  useEffect(() => {
    if (Object.keys(receiptsErrorResponses).length !== 0 && receiptsErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [receiptsErrorResponses, navigate]);

  useEffect(() => {
    dispatch(getProductListRequest());
    dispatch(getCountryListRequest());
    dispatch(
      getReceiptsRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
      }),
    );
  }, []);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      timeoutId = setTimeout(() => {
        showHandler();
        timeoutId = null;
      }, 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [calendarDate]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(receiptsErrorResponses).length !== 0 ? <ErrorResponse error={receiptsErrorResponses} /> : null}

      {Object.keys(deactivateErrorResponses).length !== 0 ? <ErrorResponse error={deactivateErrorResponses} /> : null}

      {Object.keys(productListErrorResponse).length !== 0 ? <ErrorResponse error={productListErrorResponse} /> : null}

      {Object.keys(createReceiptErrorResponse).length !== 0 ? (
        <ErrorResponse error={createReceiptErrorResponse} />
      ) : null}

      <div className={styles.loadingGroup}>
        <span className={styles.title}>Рецепти</span>
        {loading ? <Spinner /> : null}
      </div>

      <span>Створюйте і контролюйте виробничі замовлення (рецепти)</span>

      <div className={styles.filterBlock}>
        <div className={styles.firstRow}>
          <div className={styles.calendarItem}>
            <span>Пошук по:</span>
            <Select
              sx={selectStyle}
              onChange={(e: SelectChangeEvent<any>) => {
                setViewType(e.target.value);
                setGtinValue('');
                setSeriesValue('');
              }}
              value={viewType}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={1}>
                Дата створення
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={2}>
                Код GTIN
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={3}>
                Серія
              </MenuItem>
            </Select>
          </div>

          <div className={styles.calendarItem}>
            <span>Код GTIN:</span>
            <OutlinedInput
              name="Gtin"
              value={gtinValue}
              onChange={(event) => {
                setGtinValue(event.target.value.trim());
                dispatch(getReceiptsSuccess([]));
              }}
              sx={viewType === 1 || viewType === 3 ? disabledInputStyle : inputStyle}
              disabled={viewType === 1 || viewType === 3}
              inputProps={{ maxLength: 20 }}
            />
          </div>

          <div className={styles.calendarItem}>
            <span>Серія:</span>
            <OutlinedInput
              name="Series"
              value={seriesValue}
              onChange={(event) => {
                setSeriesValue(event.target.value.trim());
                dispatch(getReceiptsSuccess([]));
              }}
              sx={viewType === 1 || viewType === 2 ? disabledInputStyle : inputStyle}
              disabled={viewType === 1 || viewType === 2}
              inputProps={{ maxLength: 20 }}
            />
          </div>

          <div className={styles.calendarItem}>
            <span>Дані за пeріод:</span>
            <div className={styles.calendarRow}>
              <LocalizationProvider dateAdapter={AdapterMoment} locale={'uk'}>
                <DatePicker
                  inputFormat="MM/YYYY"
                  value={calendarDate}
                  views={['year', 'month']}
                  onChange={changeDateCalendar}
                  disabled={viewType !== 1}
                  renderInput={(params) => (
                    <TextField sx={viewType !== 1 ? calendarStyleDisabled : calendarStyleEnabled} {...params} />
                  )}
                  PaperProps={{
                    sx: {
                      '.MuiPickersCalendarHeader-label': {
                        fontSize: '13px',
                      },
                      '& .PrivatePickersYear-yearButton': {
                        fontSize: '13px',
                      },
                      '& .MuiTypography-root': {
                        fontSize: '13px',
                      },
                    },
                  }}
                />
                <Tooltip title="Місяць назад" placement="top">
                  <img
                    src={ArrowBackIcon}
                    onClick={() => (viewType !== 1 ? () => undefined : handleMonthChange(-1))}
                    alt=""
                  />
                </Tooltip>
                <Tooltip title="Місяць вперед" placement="top">
                  <img
                    src={ArrowForwardIcon}
                    onClick={() => (viewType !== 1 ? () => undefined : handleMonthChange(1))}
                    alt=""
                  />
                </Tooltip>
              </LocalizationProvider>
            </div>
          </div>

          <Button
            variant="contained"
            className={styles.btn}
            onClick={showHandler}
            disabled={
              (viewType === 2 && gtinValue.trim().length === 0) || (viewType === 3 && seriesValue.trim().length === 0)
            }
          >
            <span className={styles.btnText}>Пошук</span>
          </Button>

          <Button variant="contained" className={styles.btn} onClick={openCreateNew}>
            <span className={styles.btnText}>Новий рецепт</span>
          </Button>
        </div>

        <div className={styles.secondRow}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={receiptsResponse} clearOnPropsChange={true} />
          </div>
        </div>
      </div>

      <ReceiptsTable data={tableData} updateTable={showHandler} />

      {productListResponse && (
        <NewReceiptModal
          isShow={isShowCreateNew}
          closeModal={closeCreateNew}
          updateTable={showHandler}
          countries={countries}
        />
      )}
    </div>
  );
};
