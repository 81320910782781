import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Box, Button, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SimpleField from '../SimpleField';
import { useTranslation } from 'react-i18next';
import styles from './EditPackModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePackingRequest,
  putUpdatePackingRequest,
  putUpdatePackingSuccess,
} from '../../Handbook/Packaging/services/actions';
import { packingSelector } from '../../Handbook/Packaging/services/selector';
import DeleteEntryModal from '../DeleteEntryModal';
import useModal from '../../shared/hooks/useModal/useModal';
import { format } from 'date-fns';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface Props {
  isShown: boolean;
  data: any;
  closeModal: () => void;
}

export const EditPackModal: React.FC<Props> = ({ isShown, closeModal, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isShow: isShownDelete, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  const { updatePackResponse }: any = useSelector(packingSelector);

  const onDeleteHandler = () => {
    dispatch(deletePackingRequest({ packingId: data.id }));
    closeModal();
    closeDeleteModal();
  };

  const onSaveHandler = (values: any) => {
    const dataToSet = { ...values, weight: values.weight.toString().trim(), name: values.name.toString().trim() };
    dispatch(putUpdatePackingRequest(dataToSet));
  };

  useEffect(() => {
    if (Object.keys(updatePackResponse).length !== 0 && updatePackResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updatePackResponse]);

  return (
    <div>
      {Object.keys(data).length !== 0 ? (
        <Modal
          open={isShown}
          onClose={() => {
            dispatch(putUpdatePackingSuccess({}));
            closeModal();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styles.menu}>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                {t('EditMasterDataEntry')}
              </Typography>
              <div>
                <DeleteOutlinedIcon className={styles.icon} onClick={openDeleteModal} />
                <CloseIcon
                  className={styles.icon}
                  onClick={() => {
                    dispatch(putUpdatePackingSuccess({}));
                    closeModal();
                  }}
                />
              </div>
            </div>
            <div className={styles.editor}>
              <span className={styles.editorLegend}>
                Створено: {data?.creatorEmail},{' '}
                {data.createDate && data.createDate !== null
                  ? format(new Date(data?.createDate), 'yyyy-MM-dd HH:mm:ss')
                  : null}
                {', '}
                востаннє оновленно:{' '}
                {data.updateDate && data.updateDate !== null
                  ? format(new Date(data?.updateDate), 'yyyy-MM-dd HH:mm:ss')
                  : null}
              </span>
            </div>
            <Formik initialValues={data} onSubmit={() => undefined}>
              {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className={styles.header}>
                    <div className={styles.wrapper}>
                      <span className={styles.title}>
                        Найменування (ID {values.id})<em className={styles.required}>*</em>
                      </span>
                      <SimpleField
                        name="name"
                        maxLength={250}
                        hasMarginTop={false}
                        value={values.name}
                        validate={true}
                      />
                    </div>
                    <div className={styles.wrapper}>
                      <span className={styles.title}>
                        Вага пакування, гр <em className={styles.required}>*</em>
                      </span>
                      <SimpleField
                        size="small"
                        name="weight"
                        hasMarginTop={false}
                        value={values.weight}
                        validate={true}
                        maxLength={6}
                        type="number"
                      />
                    </div>
                  </div>
                  <section className={styles.btnSection}>
                    <>
                      {Object.keys(updatePackResponse).length !== 0 && updatePackResponse?.messages?.Failed !== null ? (
                        <span className={styles.errorMessage}>{updatePackResponse?.messages?.Failed[0]}</span>
                      ) : (
                        <div> </div>
                      )}
                    </>
                    <div>
                      <Button
                        className={styles.saveBtn}
                        variant="contained"
                        onClick={() => onSaveHandler(values)}
                        disabled={values.name.trim().length === 0 || values.weight.toString().trim().length === 0}
                      >
                        <span className={styles.btnText}>Зберегти</span>
                        <SaveIcon className={styles.icon} />
                      </Button>
                    </div>
                  </section>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
      ) : null}
      <DeleteEntryModal
        title={`Видалити пакування?`}
        isShow={isShownDelete}
        closeModal={closeDeleteModal}
        deleteHandler={onDeleteHandler}
      />
    </div>
  );
};
