import React, { useEffect, useState } from 'react';
import { Modal, Box, CardMedia, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SaveIcon from '@mui/icons-material/Save';
import styles from './ImageViewerModal.module.scss';
import { useDispatch } from 'react-redux';
import ImagePlaceholder from 'icons/NA.jpg';
import {
  getUnitTemplatesByIdRequest,
  postUpdatePhotoUnitTemplatesRequest,
} from '../../Templates/UnitTemplates/services/actions';
import {
  getBoxTemplatesByIdRequest,
  postUpdatePhotoBoxTemplatesRequest,
} from '../../Templates/BoxTemplates/services/actions';
import {
  getPalletTemplatesByIdRequest,
  postUpdatePhotoPalletTemplatesRequest,
} from '../../Templates/PalletTemplates/services/actions';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '900',
  height: '90%',
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface Props {
  isShownViewer: boolean;
  closeViewerModal: () => void;
  extended: boolean;
  img?: any;
  labelTemplateId?: number;
  level?: any;
}

export const ImageViewerModal: React.FC<Props> = ({
  isShownViewer,
  closeViewerModal,
  extended,
  img,
  labelTemplateId,
  level,
}) => {
  const [file, setFile] = useState<any>();
  const [fileToShow, setFileToShow] = useState<any>();
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    setFile(e.target.files[0]);
    setFileToShow(URL.createObjectURL(e.target.files[0]));
  };

  const onSaveHandler = () => {
    if (level === 0) {
      dispatch(postUpdatePhotoUnitTemplatesRequest({ labelTemplateId, file }));
    }
    if (level === 2) {
      dispatch(postUpdatePhotoBoxTemplatesRequest({ labelTemplateId, file }));
    }
    if (level === 3) {
      dispatch(postUpdatePhotoPalletTemplatesRequest({ labelTemplateId, file }));
    }

    setTimeout(() => {
      closeViewerModal();
      if (level === 0) {
        dispatch(getUnitTemplatesByIdRequest({ labelTemplateId }));
      }
      if (level === 2) {
        dispatch(getBoxTemplatesByIdRequest({ labelTemplateId }));
      }
      if (level === 3) {
        dispatch(getPalletTemplatesByIdRequest({ labelTemplateId }));
      }
    }, 1000);
  };

  useEffect(() => {
    if (img) {
      setFileToShow(img);
    } else {
      setFileToShow(ImagePlaceholder);
    }
  }, [img]);

  return (
    <div>
      <Modal
        open={isShownViewer}
        onClose={closeViewerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.header}>
            <CloseIcon className={styles.icon} onClick={closeViewerModal} />
          </div>
          {extended ? (
            <>
              <CardMedia
                component="img"
                image={fileToShow}
                sx={{ objectFit: 'contain', width: '100%', height: '80%' }}
              />
              <section className={styles.btnGroup}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={(e) => handleChange(e)}
                />
                <label htmlFor="raised-button-file">
                  <Button className={styles.btn} variant="contained" component="span">
                    <span className={styles.btnText}>Вибрати</span>
                    <AddAPhotoIcon className={styles.icon} />
                  </Button>
                </label>
                <Button className={styles.btn} variant="contained" onClick={onSaveHandler}>
                  <span className={styles.btnText}>Зберегти</span>
                  <SaveIcon className={styles.icon} />
                </Button>
              </section>
            </>
          ) : (
            <>
              <CardMedia
                component="img"
                image={fileToShow}
                sx={{ objectFit: 'contain', width: '100%', height: '80%', bgcolor: '#ECEFF1' }}
              />
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
